import React from 'react';
import { Dialog, Box, Button, Divider, DialogActions } from '@material-ui/core';
import QRCode from 'react-qr-code';
import { Typography } from '@material-ui/core';

/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {VoidFunction} props.onClose
 * @param {string} props.value
 * @returns
 */
const QrCode = ({ open = false, onClose = () => {}, value = '' }) => {
  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Box p={4}>
        <Box display={'flex'} justifyContent="center" alignItems="center">
          <QRCode value={value} />
        </Box>
      </Box>
      <Divider />

      <DialogActions>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(QrCode);

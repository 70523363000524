import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, Grid } from '@material-ui/core';
import DragAndDropComponent from 'src/components/DragAndDropComponent';
import FileList from './FileList';
import { FileUploadButton } from './FileUpload';
import { useAttachments } from './hooks';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *:not(:last-child) ': {
      marginBottom: theme.spacing(3)
    }
  },
  defaultImg: {
    width: '150px',
    height: '150px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px'
    }
  }
}));

/**
 * @param {object} props
 * @param {function} props.getFiles Function to get the files
 * @param {number} props.maxFileSize Maximum file size in bytes (default: 5242880) (5MB)
 * @param {number} props.maxFiles Maximum number of files (default: 5)
 * @returns {JSX.Element}
 */
function Attachments({ getFiles, maxFileSize = 5242880, maxFiles = 5 }) {
  const classes = useStyles();
  const {
    attachments,
    handlePasteItem,
    handleDropItem,
    handleChangeFile,
    handleChangeFileWithTag,
    handleDeleteAttachments
  } = useAttachments(maxFileSize, maxFiles);

  const onDropItem = files => {
    handleDropItem(files);
  };

  const onPasteItem = files => {
    handlePasteItem(files);
  };

  const onChangeFile = files => {
    handleChangeFile(files);
  };

  const onChangeFileWithTag = files => {
    handleChangeFileWithTag(files);
  };

  useEffect(() => {
    if (!attachments.length) return;
    getFiles(attachments);
  }, [attachments]);

  return (
    <Box className={classes.root}>
      <DragAndDropComponent onDropItem={onDropItem} onPaste={onPasteItem}>
        {!attachments.length > 0 && (
          <Box
            display="flex"
            p={2}
            flexDirection={{
              xs: 'column',
              sm: 'column',
              md: 'row',
              lg: 'row',
              xl: 'row'
            }}
            alignItems="center"
            justifyContent="center"
            gridGap="1em"
          >
            <img
              className={classes.defaultImg}
              alt="Under development"
              src="/static/images/addFiles.svg"
            />
            <Box
              display="flex"
              alignItems="center"
              flexDirection={{
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row'
              }}
              gridGap=".5em"
            >
              <Typography variant="body1" color="textSecondary" component={Box}>
                Drop, Paste or Browse here.
              </Typography>
              <FileUploadButton onChange={e => onChangeFile(e.target.files)} />
            </Box>
          </Box>
        )}

        {attachments.length > 0 && (
          <FileList
            attachments={attachments}
            onChange={e => onChangeFile(e.target.files)}
            onChangeFileWithTag={e => onChangeFileWithTag(e.target.files)}
            onDelete={handleDeleteAttachments}
          />
        )}
      </DragAndDropComponent>
    </Box>
  );
}

export default Attachments;

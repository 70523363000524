import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  useTheme,
  withStyles
} from '@material-ui/core';
import { useConfetti, useNotif, useRequest, useToggle } from 'src/hooks';
import { orange } from '@material-ui/core/colors';
import { Attachments, Field, Loading } from 'src/components';
import { useNavigate, useParams } from 'react-router';
import { isEmpty, sortBy } from 'lodash';
import { CheckCircle } from '@material-ui/icons';
import { portal } from 'src/lib/endpoints';

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  title: {
    fontSize: '3rem',
    fontWeight: 900
  }
}));

const ColorButton = withStyles(() => ({
  root: {
    color: '#fff',
    backgroundColor: orange[500],
    '&:hover': {
      backgroundColor: orange[300]
    }
  }
}))(Button);

const GuestPortal = () => {
  // const [sourceParams] = useSearchParams();
  const { start } = useConfetti();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const { error, success } = useNotif();
  const { ixJCd, cdBiz, cdPortal } = useParams();
  const [guestTransaction, setGuestTransaction] = useState();
  const [guestTransactionMeta, setGuestTransactionMeta] = useState();
  const [isLoading, loadingOn, loadingOff] = useToggle();
  const [isSubmitted, submittedOn, submittedOff] = useToggle();
  const [isSuccess, successShow, successHide] = useToggle();
  const req = useRequest(loadingOn, loadingOff, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
  const [formData, setFormData] = useState({});
  const [files, setFiles] = useState([]);

  const isVisible = fld => guestTransactionMeta?.flds?.includes(fld);

  const fetch = async cancelCallback => {
    const jcdMetaRes = await req.get(
      `/portal/guest/${cdBiz}/${cdPortal}/meta`,
      {},
      cancelCallback
    );
    const transMetaRes = await req.post(
      `/portal/guest/${cdBiz}/${cdPortal}/trans/${jcdMetaRes.data
        ?.transactions[0]?.ixJCd || ixJCd}/meta`,
      {},
      {},
      cancelCallback
    );

    if (jcdMetaRes.isCancelled || transMetaRes.isCancelled) return;
    if (!jcdMetaRes.success || !transMetaRes.success) {
      error(jcdMetaRes.errorMessage);
      error(transMetaRes.errorMessage);
      return;
    }

    setGuestTransaction(jcdMetaRes.data.transactions[0]);
    setGuestTransactionMeta(transMetaRes.data);
  };

  const onChangeField = e => {
    console.log(e.target.name, e.target.value);

    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeKVS = e => {
    setFormData(prev => ({
      ...prev,
      kvs: {
        ...prev.kvs,
        [e.target.name]: e.target.value
      }
    }));
  };

  const isFieldFalsy = field => {
    if (isSubmitted) {
      return !formData[field] || formData[field].length === 0;
    }
  };

  const isKVSFieldFalsy = field => {
    if (isSubmitted) {
      if (guestTransactionMeta?.kvs[field]?.required) {
        return !formData?.kvs?.[field] || formData?.kvs?.[field].length === 0;
      }
    }
  };

  const onSubmit = async () => {
    submittedOn();

    if (!formData?.Name)
      return error(
        `${guestTransactionMeta?.labels?.Name ?? 'Name'} is required`
      );

    if (!isEmpty(guestTransactionMeta?.kvs)) {
      for (const key in guestTransactionMeta.kvs) {
        if (guestTransactionMeta.kvs[key].required && !formData?.kvs?.[key]) {
          return error(
            `${guestTransactionMeta?.kvs[key]?.label ??
              guestTransactionMeta?.kvs[key]?.caption ??
              key} is required`
          );
        }
      }
    }

    if (!formData?.Particulars)
      return error(
        `${guestTransactionMeta?.labels?.Particulars ??
          'Particulars'} is required`
      );

    const payload = {
      ...formData
    };

    if (!isEmpty(payload.kvs)) {
      payload.kvs = formData.kvs;
    }

    const res = await req.post(
      portal.saveGuestTransaction(cdBiz, cdPortal, guestTransaction?.ixJCd),
      payload
    );

    if (!res.success) {
      submittedOff();
      return error(res.errorMessage);
    }

    if (files.length === 0 && guestTransactionMeta?.attachments?.required) {
      submittedOff();
      return error('Attachments are required!');
    }

    if (files.length > 0) {
      if (!res.data?.jid) {
        submittedOff();
        return error('Upload failed. Something went wrong!');
      }

      for (const file of files) {
        const fileData = new FormData();
        fileData.append('file', file);

        const fileRes = await req.post(
          portal.uploadGuestAttachments(
            cdBiz,
            cdPortal,
            guestTransaction?.ixJCd,
            res.data?.jid
          ),
          fileData
        );

        if (!fileRes.success) {
          submittedOff();
          return error(fileRes.errorMessage);
        }
      }
    }

    success('Success');
    setFormData({});
    submittedOff();
    successShow();
    start();
  };

  const onClose = () => {
    successHide();
    start();
    navigate('/');
  };

  useEffect(() => {
    for (const key in guestTransactionMeta?.kvs) {
      if (
        guestTransactionMeta?.kvs[key]?.value ||
        guestTransactionMeta?.kvs[key]?.defaultValue
      ) {
        setFormData({
          ...formData,
          kvs: {
            ...formData.kvs,
            [key]:
              guestTransactionMeta?.kvs[key]?.value ||
              guestTransactionMeta?.kvs[key]?.defaultValue
          }
        });
      }
    }
  }, [guestTransactionMeta?.kvs]);

  useEffect(() => {
    let cancel = () => {};
    fetch(c => (cancel = c));
    return cancel;
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box borderLeft={5} borderColor="primary.main" pl={1} my={2}>
                    <Typography variant="h1" color="textSecondary">
                      {(guestTransaction?.label_singular ||
                        guestTransactionMeta?.label) ??
                        'Message Us'}
                    </Typography>
                  </Box>
                </Grid>
                {isVisible('Name') && (
                  <Grid item xs={12}>
                    <Field
                      onChangeField={onChangeField}
                      data={{
                        disabled: isLoading,
                        caption: guestTransactionMeta?.labels?.Name ?? 'Name',
                        value: formData['Name'],
                        fld: 'Name',
                        type: 'str',
                        error: isFieldFalsy('Name')
                      }}
                    />
                  </Grid>
                )}

                {sortBy(
                  Object.entries(guestTransactionMeta?.kvs || {}),
                  ([, props]) => props.sort
                ).map(([key, props]) => (
                  <Grid item xs={12} key={key}>
                    <Field
                      onChangeField={onChangeKVS}
                      data={{
                        disabled: isLoading,
                        caption: guestTransactionMeta?.kvs[key]?.label ?? key,
                        value: formData?.kvs?.[key] ?? '',
                        type: 'str',
                        fld: key,
                        error: isKVSFieldFalsy(key),
                        ...props
                      }}
                    />
                  </Grid>
                ))}

                {isVisible('Particulars') && (
                  <Grid item xs={12}>
                    <Field
                      onChangeField={onChangeField}
                      data={{
                        disabled: isLoading,
                        caption:
                          guestTransactionMeta?.labels?.Particulars ??
                          'Particulars',
                        value: formData['Particulars'],
                        fld: 'Particulars',
                        type: 'str',
                        isLongText: true,
                        error: isFieldFalsy('Particulars')
                      }}
                    />
                  </Grid>
                )}

                {guestTransactionMeta?.hasOwnProperty('attachments') &&
                  guestTransactionMeta?.attachments?.allow && (
                    <Grid item xs={12}>
                      <Typography variant="h4" color="primary">
                        Attachments
                      </Typography>

                      {guestTransactionMeta?.attachments?.hasOwnProperty(
                        'label'
                      ) &&
                        guestTransactionMeta?.attachments?.label && (
                          <Typography variant="subtitle2" color="textSecondary">
                            {guestTransactionMeta?.attachments?.label}
                          </Typography>
                        )}

                      <Attachments
                        getFiles={files =>
                          setFiles(prev => [...prev, ...files])
                        }
                        maxFileSize={
                          guestTransactionMeta?.attachments?.maxFileSize
                        }
                        maxFiles={guestTransactionMeta?.attachments?.maxFiles}
                      />
                    </Grid>
                  )}

                <Grid item xs={3}>
                  <ColorButton
                    type="submit"
                    disabled={isLoading}
                    size="large"
                    fullWidth
                    onClick={onSubmit}
                    endIcon={
                      isLoading ? (
                        <CircularProgress size={15} color="secondary" />
                      ) : null
                    }
                  >
                    Submit
                  </ColorButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={isSuccess} disableEscapeKeyDown fullWidth>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          bgcolor="white"
          borderRadius={4}
        >
          <Box
            display="flex"
            alignItems="center"
            p={4}
            justifyContent="center"
            bgcolor={theme.palette.primary.main}
            style={{
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4
            }}
          >
            <CheckCircle
              style={{
                color: theme.palette.primary.contrastText,
                fontSize: 50
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gridGap={4}
            mt={2}
            px={2}
            pb={2}
          >
            <Typography variant="h3" align="center">
              Success
            </Typography>
            <Typography variant="subtitle1" align="center">
              Your application has been submitted successfully! <br />
              We will get back to you soon.
            </Typography>

            <Button
              variant="contained"
              color="outlined"
              onClick={onClose}
              fullWidth
              style={{
                marginTop: 8
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Container>
  );
};

export default GuestPortal;

import React, { useMemo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNotif, useRequest, useToggle } from 'src/hooks';
import {
  isValidTIN,
  removeUnderscore,
  getTitleFormat,
  isTINEmpty,
  setObjectValue
} from 'src/helpers';
import { cloneDeep, isEmpty, isEqual, isObject } from 'lodash';
import { useConfirmDialog } from '../ConfirmDialog';
import { useEffect } from 'react';
import moment from 'moment';

const excludeFlds = [
  'ixSubParent',
  'ixSubLink1',
  'ixSubLink2',
  'ixSubLink3',
  'ixSubLink4',
  'ixSubLink5'
];

function getKVSValue(kvs = [], key = '', defaultValue = '') {
  const kvs_value = kvs.find(item => item.key === key);

  return kvs_value ? kvs_value.value : defaultValue;
}

const useSubAccForm = ({ subType = {}, subTypeMeta = {}, subAcc = {} }) => {
  const notify = useNotif();
  const { prompt, closeConfimationModal } = useConfirmDialog();

  const { tax = {} } = useSelector(state => state.bizMeta);
  const canOverrideBlankTin =
    tax?.customers?.type_id && tax?.customers?.type_id === subType?.ixSubType;
  const allowDuplicateTitle = subTypeMeta?.allow_duplicate || false;

  const {
    req_flds,
    kvs: kvsMeta = {},
    parent = {},
    subLink = {},
    ui_layout = {}
  } = subTypeMeta;

  const flds = useMemo(() => {
    const meta_flds = subTypeMeta?.flds ?? [];

    if (Boolean(subTypeMeta?.ui_layout?.show_price_type))
      return [...meta_flds, 'invPriceType'];

    if (Boolean(subTypeMeta?.ui_layout?.show_imp_id))
      return [...meta_flds, 'importID'];

    return meta_flds;
  }, [subTypeMeta?.flds]);

  const [isLoading, loadingOn, loadingOff] = useToggle();
  const [advanceShown, , , toggleAdvance] = useToggle();

  const [errors, setErrors] = useState({});
  const [currentSubVal, setCurrentSubVal] = useState({});
  const [newSubVal, setNewSubVal] = useState({});

  const request = useRequest(loadingOn, loadingOff);

  function removeError(field) {
    setErrors(state => ({
      ...state,
      [field]: ''
    }));
  }

  /**
   * Updates the newSubVal state by setting a value for a given key
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  function updateField(event) {
    const { name, value } = event.target;

    setNewSubVal(state => {
      const newState = { ...state };

      if (name.startsWith('kvs.')) {
        const keyPath = name.replace('kvs.', '');
        const keyPathArray = keyPath.split('.');
        const key = keyPathArray[keyPathArray.length - 1];

        const updatedKvs = newState?.kvs || [];
        const kvsKey = keyPathArray.shift();

        const keysLength = keyPathArray.length;

        const kvsIndex = updatedKvs.findIndex(item => item.key === kvsKey);

        // if not a nested keys
        if (keysLength === 0) {
          if (kvsIndex === -1) updatedKvs.push({ key: kvsKey, value });
          else updatedKvs[kvsIndex].value = value;
        } else {
          let ref;

          if (kvsIndex === -1) {
            const temp = {};
            updatedKvs.push({
              key: kvsKey,
              value: temp
            });
            ref = temp;
          } else {
            if (isObject(updatedKvs[kvsIndex].value)) {
              ref = updatedKvs[kvsIndex].value;
            } else {
              const temp = {};
              updatedKvs[kvsIndex].value = temp;
              ref = temp;
            }
          }

          for (let i = 0; i < keysLength; i++) {
            const key = keyPathArray[i];
            if (i === keysLength - 1) ref[key] = value;
            else {
              ref[key] = {};
              ref = ref[key];
            }
          }
        }
        newState.kvs = updatedKvs;
      } else {
        newState[name] = value;
        if (
          !subAcc?.ixSub &&
          name === 'sSub' &&
          (subTypeMeta?.flds ?? []).includes('taxRegName')
        )
          newState.taxRegName = value;

        if ((subTypeMeta?.flds ?? []).includes('taxRegName'))
          newState.taxRegName = getTitleFormat({
            nameProps: {
              lname: newState?.lname || '',
              fname: newState?.fname || '',
              mname: newState?.mname || '',
              sname: newState?.sname || '',
              sSub: newState?.sSub || ''
            },
            acc_title: subTypeMeta?.acc_title
          });
      }

      return newState;
    });
    removeError(name);
  }

  function updateKvsLogs(logs = []) {
    setCurrentSubVal(state => {
      const newState = { ...state };

      if (!('kvs' in newState))
        return {
          ...newState,
          kvs: [{ key: 'logs', value: logs }]
        };

      const index = newState.kvs.findIndex(item => item.key === 'logs');

      if (index === -1) {
        return {
          ...newState,
          kvs: [
            ...newState.kvs,
            {
              key: 'logs',
              value: logs
            }
          ]
        };
      }

      newState.kvs[index].value = logs;
      return newState;
    });
    setNewSubVal(state => {
      const newState = { ...state };

      if (!('kvs' in newState))
        return {
          ...newState,
          kvs: [{ key: 'logs', value: logs }]
        };

      const index = newState.kvs.findIndex(item => item.key === 'logs');

      if (index === -1) {
        return {
          ...newState,
          kvs: [
            ...newState.kvs,
            {
              key: 'logs',
              value: logs
            }
          ]
        };
      }

      newState.kvs[index].value = logs;
      return newState;
    });
  }

  function updateKVSField(event) {
    const { name, value } = event.target;

    setNewSubVal(state => {
      const newState = { ...state };

      if (!('kvs' in newState))
        return {
          ...newState,
          kvs: [{ key: name, value }]
        };

      const index = newState.kvs.findIndex(item => item.key === name);

      if (index === -1) {
        return {
          ...newState,
          kvs: [
            ...newState.kvs,
            {
              key: name,
              value
            }
          ]
        };
      }

      newState.kvs[index].value = value;
      return newState;
    });

    removeError(name);
  }

  function updateSubLink(idKey, titleKey, { ixSub, sSub }) {
    setNewSubVal(state => ({
      ...state,
      [idKey]: ixSub,
      [titleKey]: sSub
    }));

    removeError(idKey);
  }

  function updateSubParent({ ixSub, sSub }) {
    setNewSubVal(state => ({
      ...state,
      ixSubParent: ixSub,
      sSubParent: sSub
    }));

    removeError('ixSubParent');
  }

  function addAtc(atc) {
    setNewSubVal(state => ({
      ...state,
      atc_list: [...(state?.atc_list || []), atc]
    }));
  }

  const removeAtc = atc => {
    setNewSubVal(state => ({
      ...state,
      atc_list: state.atc_list.filter(item => item !== atc)
    }));
  };

  const validateForm = () => {
    let hasError = false;
    const errorFields = [];

    const infoFlds = flds.filter(fld => !excludeFlds.includes(fld));

    infoFlds.forEach(fld => {
      if (!req_flds.includes(fld) || (fld === 'TIN' && canOverrideBlankTin))
        return;

      if (!newSubVal[fld] || newSubVal[fld] === '') {
        errorFields.push(fld);
        setErrors(prev => ({
          ...prev,
          [fld]: 'This field is required'
        }));

        hasError = true;
      }

      if (fld === 'TIN') {
        const TIN = newSubVal?.TIN || '';

        if (isTINEmpty(TIN)) {
          errorFields.push(fld);
          setErrors(prev => ({
            ...prev,
            ['TIN']: 'This field is required'
          }));

          hasError = true;
        }

        if (TIN.length !== 0 && !isValidTIN(TIN)) {
          errorFields.push(fld);
          setErrors(prev => ({
            ...prev,
            ['TIN']: 'Invalid TIN format'
          }));

          hasError = true;
        }
      }
    });

    //kvs
    if (!isEmpty(subTypeMeta.kvs)) {
      Object.entries(subTypeMeta?.kvs).forEach(([key, props]) => {
        if (props?.isRequired) {
          if (!newSubVal?.kvs) {
            errorFields.push(key);
            setErrors(prev => ({
              ...prev,
              kvs: {
                ...prev.kvs,
                [key]: 'This field is required.'
              }
            }));

            hasError = true;
            return;
          }

          const index = newSubVal?.kvs.findIndex(data => data.key === key);

          if (index === -1) {
            errorFields.push(key);
            setErrors(prev => ({
              ...prev,
              kvs: {
                ...prev.kvs,
                [key]: 'This field is required.'
              }
            }));

            hasError = true;
            return;
          }

          if (
            newSubVal.kvs[index].value === '' ||
            newSubVal.kvs[index].value === null
          ) {
            errorFields.push(key);
            setErrors(prev => ({
              ...prev,
              kvs: {
                ...prev.kvs,
                [key]: 'This field is required.'
              }
            }));

            hasError = true;
            return;
          }
        }
      });
    }

    // parent fld
    if (
      subTypeMeta?.parent?.ixSubType !== 0 &&
      req_flds.includes('ixSubParent') &&
      !newSubVal?.ixSubParent
    ) {
      errorFields.push('ixSubParent');
      setErrors(prev => ({
        ...prev,
        ixSubParent: 'This field is required'
      }));
      hasError = true;
    }

    Object.keys(subTypeMeta?.subLink || {}).forEach(key => {
      if (!req_flds.includes('ixSubLink' + key)) return;

      if (!newSubVal?.['ixSubLink' + key]) {
        hasError = true;

        errorFields.push('ixSubLink' + key);
        setErrors(prev => ({
          ...prev,
          ['ixSubLink' + key]: `This field is required`
        }));
      }
    });

    return { hasError, errorFields };
  };

  async function confirmDuplicateTitle(confirmBlankTIN = false) {
    return await prompt({
      title: <Typography variant="h4">Confirm Duplicate Title</Typography>,
      disableTypography: true,
      body: (
        <Typography variant="body1" align="justify">
          This title has already been used. Do you still want to proceed?
        </Typography>
      ),
      okText: 'Yes',
      cancelText: 'Cancel',
      okProps: {
        variant: 'contained',
        color: 'primary'
      },
      cancelProps: {
        color: 'primary'
      },
      onCancel: () => {
        closeConfimationModal();
        return { success: false };
      },
      onOk: () => {
        return save(confirmBlankTIN, true);
      }
    });
  }

  async function validateTin() {
    return await prompt({
      title: <Typography variant="h4">Confirm Blank TIN</Typography>,
      disableTypography: true,
      body: (
        <Typography variant="body1" align="justify">
          Are you sure you want to save this with a blank TIN?
        </Typography>
      ),
      okText: 'Yes',
      cancelText: 'Cancel',
      okProps: {
        variant: 'contained',
        color: 'primary'
      },
      cancelProps: {
        color: 'primary'
      },
      onCancel: () => {
        closeConfimationModal();
        return { success: false };
      },
      onOk: () => {
        return save(true);
      }
    });
  }

  function getUpdates() {
    const updates = {},
      kvsUpdates = {};

    flds.forEach(fld => {
      if (
        (fld === 'TIN' || fld === 'contactNo') &&
        currentSubVal?.[fld] !== newSubVal?.[fld]
      ) {
        updates[fld] = removeUnderscore(newSubVal?.[fld] || '');
        return;
      }

      if (!isEqual(currentSubVal?.[fld], newSubVal?.[fld]))
        updates[fld] = newSubVal?.[fld] || '';
    });

    if (ui_layout?.showRate) updates.rate = newSubVal?.rate ?? 0;

    if (flds.includes('fname')) {
      if (currentSubVal?.['pname'] !== newSubVal?.['pname'])
        updates['pname'] = newSubVal?.['pname'] || '';

      if (currentSubVal?.['sname'] !== newSubVal?.['sname'])
        updates['sname'] = newSubVal?.['sname'] || '';
    }

    Object.entries(kvsMeta).forEach(([key, props]) => {
      const oldValue = (currentSubVal?.kvs || []).find(
        item => item.key === key
      );
      const newValue = (newSubVal?.kvs || []).find(item => item.key === key);

      if (!isEqual(oldValue?.value, newValue?.value))
        kvsUpdates[key] = newValue?.value || '';
    });

    if (!isEmpty(kvsUpdates)) updates['kvs'] = kvsUpdates;

    if (
      parent?.ixSubType &&
      currentSubVal?.ixSubParent !== newSubVal?.ixSubParent
    )
      updates.ixSubParent = newSubVal?.ixSubParent || 0;

    Object.entries(subLink).forEach(([key, { ixSubType }]) => {
      const fldKey = 'ixSubLink' + key;

      if (ixSubType && currentSubVal?.[fldKey] !== newSubVal?.[fldKey])
        updates[fldKey] = newSubVal?.[fldKey] || 0;
    });

    if (currentSubVal.subStatus !== newSubVal.subStatus)
      updates['subStatus'] = newSubVal['subStatus'];

    return updates;
  }

  async function save(
    blank_tin_confirmed = false,
    duplicate_confirmed = false
  ) {
    const { hasError, errorFields } = validateForm();

    if (hasError) {
      notify.info('Please fill out the required fields');
      return {
        success: false,
        errorFields
      };
    }

    const updates = getUpdates();

    if (isEmpty(updates)) {
      notify.info('No changes made.');
      return {
        success: false
      };
    }

    if (
      canOverrideBlankTin &&
      (('TIN' in updates && !updates.TIN) || !newSubVal?.TIN)
    ) {
      if (blank_tin_confirmed) updates['confirmed_blank_tin'] = true;
      else {
        return validateTin();
      }
    }

    if (allowDuplicateTitle && duplicate_confirmed)
      updates['confirmed_duplicate'] = true;

    let new_logs;
    const hasLogs =
      kvsMeta.hasOwnProperty('logs') &&
      kvsMeta?.logs?.type === 'json' &&
      kvsMeta?.logs?.special === 'logs';

    if (hasLogs) {
      const logs = getKVSValue(currentSubVal?.kvs || [], 'logs', []);

      new_logs = [
        {
          timestamp: moment().format(),
          updates: cloneDeep(updates)
        },
        ...logs
      ];

      updates.kvs = {
        ...(updates?.kvs || {}),
        logs: new_logs
      };
    }

    const nameFieldsChanged = ['lname', 'fname', 'mname', 'sname'].some(
      fld => fld in updates
    );

    if (nameFieldsChanged)
      updates['sSub'] = getTitleFormat({
        nameProps: {
          lname: newSubVal?.lname || '',
          fname: newSubVal?.fname || '',
          mname: newSubVal?.mname || '',
          sname: newSubVal?.sname || '',
          sSub: newSubVal?.sSub || ''
        },
        acc_title: subTypeMeta?.acc_title
      });

    if ((subTypeMeta?.flds ?? []).includes('taxRegName'))
      updates['taxRegName'] = getTitleFormat({
        nameProps: {
          lname: newSubVal?.lname || '',
          fname: newSubVal?.fname || '',
          mname: newSubVal?.mname || '',
          sname: newSubVal?.sname || '',
          sSub: newSubVal?.sSub || ''
        },
        acc_title: subTypeMeta?.acc_title
      });

    const { success, error, data } = currentSubVal?.ixSub
      ? await request.put('/setup/sub/' + currentSubVal.ixSub, {
          values: updates
        })
      : await request.post('/setup/sub', {
          ...updates,
          ixSubType: subType.ixSubType
        });

    if (success) {
      const update = currentSubVal?.ixSub
        ? { ...newSubVal }
        : { ixSub: data.id, ...newSubVal };

      if (nameFieldsChanged) update.sSub = updates.sSub;

      setCurrentSubVal(cloneDeep(update));
      if (hasLogs) updateKvsLogs(new_logs);

      notify.success('Successfully saved.');

      return {
        success: true,
        data: {
          ixSub: update.ixSub,
          sSub: update.sSub
        }
      };
    }

    if (
      allowDuplicateTitle &&
      !success &&
      (error?.data?.errors || []).some(
        errorItem => errorItem?.ob?.duplicate_title
      )
    ) {
      return confirmDuplicateTitle(blank_tin_confirmed);
    }

    notify.error(error?.data?.msg || error?.data);

    (error?.data?.errors || []).forEach(error => {
      if (error?.msg) notify.error(error?.msg);
    });

    Object.entries(error?.data?.validation_errors ?? {}).forEach(
      ([key, value]) => {
        setErrors(prev => ({ ...prev, [key]: value.join(',') }));
      }
    );

    return { success: false };
  }

  function hasChanges(key, value) {
    return value !== currentSubVal[key];
  }

  useEffect(() => {
    setCurrentSubVal(cloneDeep(subAcc));
    setNewSubVal(cloneDeep(subAcc));
  }, [subAcc]);

  return {
    isLoading,
    sub: newSubVal,
    currentSubVal,
    errors,
    updateField,
    updateKVSField,
    updateSubLink,
    updateSubParent,
    addAtc,
    removeAtc,
    save,
    advanceShown,
    toggleAdvance,
    hasChanges
  };
};

export default useSubAccForm;

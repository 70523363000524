import { Log } from 'src/views/trans/Payroll/models';
import { formatTimeToStr } from 'src/utils';
import moment from 'moment';

/**
 * @param {Log[]} timeLogs
 * @param {string} att_start
 * @param {string} att_end
 * @param {boolean} view_prev_ot
 */
function generateTimeLogsData(
  timeLogs,
  att_start,
  att_end,
  view_prev_ot = false
) {
  const res = [];

  if (view_prev_ot) {
    res.push({
      ixDate: 'Previous Payroll  ',
      renderPrevPr: true,
      BIO1: 0,
      BIO2: 0,
      BIO3: 0,
      BIO4: 0,
      BIO5: 0,
      BIO6: 0,
      BIO7: 0,
      BIO8: 0,
      BIO9: 0,
      BIO10: 0,
      BIO11: 0,
      BIO12: 0,
      SYS_AM_IN: 0,
      SYS_AM_OUT: 0,
      SYS_PM_IN: 0,
      SYS_PM_OUT: 0,
      SYS_OT_OUT: 0,
      SYS_OT_OUT: 0,
      EMP_AM_IN: 0,
      EMP_AM_OUT: 0,
      EMP_PM_IN: 0,
      EMP_PM_OUT: 0,
      EMP_OT_IN: 0,
      EMP_OT_OUT: 0,
      HR_AM_IN: 0,
      HR_AM_OUT: 0,
      HR_PM_IN: 0,
      HR_PM_OUT: 0,
      HR_OT_IN: 0,
      HR_OT_OUT: 0,
      SHF1_IN: 0,
      SHF1_OUT: 0,
      SHF2_IN: 0,
      SHF2_OUT: 0,
      SHF1_HR_IN: 0,
      SHF1_HR_OUT: 0,
      SHF2_HR_IN: 0,
      SHF2_HR_OUT: 0
    });
  }

  const start = moment(att_start),
    end = moment(att_end);

  const totals = {
    vOT: 0,
    vOT_RH: 0,
    vOT_SH: 0,
    vOT_ND: 0,
    vOT_ND_RH: 0,
    vOT_ND_SH: 0,
    vOT_RD: 0,
    vOT_RD_OT: 0,
    vOT_RD_RH: 0,
    vOT_RD_SH: 0,
    vOT_BRK: 0,

    vND: 0,
    vND_RH: 0,
    vND_SH: 0,
    vND_RD: 0,
    vND_RD_RH: 0,
    vND_RD_SH: 0,

    vPresent: 0,
    vHrs: 0,
    vAbsent: 0,
    vLate: 0,
    vUT: 0,
    vOB: 0,
    vRH: 0,
    vSH: 0,
    vSL: 0,
    vVL: 0,
    vML: 0,
    vPL: 0,

    var1: 0,
    var2: 0,
    var3: 0,
    var4: 0,
    var5: 0,
    var6: 0,
    var7: 0,
    var8: 0,
    var9: 0
  };

  /**
   * @type {Object.<number, Log>}
   */
  const dates = {};
  for (const log of timeLogs) {
    dates[log.ixDate] = log;
  }

  while (start.isSameOrBefore(end)) {
    const ixDate = start.format('YYYYMMDD');

    const {
      BIO1,
      BIO2,
      BIO3,
      BIO4,
      BIO5,
      BIO6,
      BIO7,
      BIO8,
      BIO9,
      BIO10,
      BIO11,
      BIO12,
      SYS_AM_IN,
      SYS_AM_OUT,
      SYS_PM_IN,
      SYS_PM_OUT,
      SYS_OT_IN,
      SYS_OT_OUT,
      HR_AM_IN,
      HR_AM_OUT,
      HR_PM_IN,
      HR_PM_OUT,
      HR_OT_IN,
      HR_OT_OUT,
      SHF1_HR_IN,
      SHF1_HR_OUT,
      SHF2_HR_IN,
      SHF2_HR_OUT,
      ...props
    } = dates[ixDate];

    res.push({
      ...props,
      BIO1: formatTimeToStr(BIO1),
      BIO2: formatTimeToStr(BIO2),
      BIO3: formatTimeToStr(BIO3),
      BIO4: formatTimeToStr(BIO4),
      BIO5: formatTimeToStr(BIO5),
      BIO6: formatTimeToStr(BIO6),
      BIO7: formatTimeToStr(BIO7),
      BIO8: formatTimeToStr(BIO8),
      BIO9: formatTimeToStr(BIO9),
      BIO10: formatTimeToStr(BIO10),
      BIO11: formatTimeToStr(BIO11),
      BIO12: formatTimeToStr(BIO12),
      SYS_AM_IN: formatTimeToStr(SYS_AM_IN),
      SYS_AM_OUT: formatTimeToStr(SYS_AM_OUT),
      SYS_PM_IN: formatTimeToStr(SYS_PM_IN),
      SYS_PM_OUT: formatTimeToStr(SYS_PM_OUT),
      SYS_OT_OUT: formatTimeToStr(SYS_OT_OUT),
      SYS_OT_OUT: formatTimeToStr(SYS_OT_OUT),
      EMP_AM_IN: formatTimeToStr(HR_AM_IN),
      EMP_AM_OUT: formatTimeToStr(HR_AM_OUT),
      EMP_PM_IN: formatTimeToStr(HR_PM_IN),
      EMP_PM_OUT: formatTimeToStr(HR_PM_OUT),
      EMP_OT_IN: formatTimeToStr(HR_OT_IN),
      EMP_OT_OUT: formatTimeToStr(HR_OT_OUT),
      HR_AM_IN: formatTimeToStr(HR_AM_IN),
      HR_AM_OUT: formatTimeToStr(HR_AM_OUT),
      HR_PM_IN: formatTimeToStr(HR_PM_IN),
      HR_PM_OUT: formatTimeToStr(HR_PM_OUT),
      HR_OT_IN: formatTimeToStr(HR_OT_IN),
      HR_OT_OUT: formatTimeToStr(HR_OT_OUT),
      SHF1_IN: formatTimeToStr(SHF1_HR_IN),
      SHF1_OUT: formatTimeToStr(SHF1_HR_OUT),
      SHF2_IN: formatTimeToStr(SHF2_HR_IN),
      SHF2_OUT: formatTimeToStr(SHF2_HR_OUT),
      SHF1_HR_IN: formatTimeToStr(SHF1_HR_IN),
      SHF1_HR_OUT: formatTimeToStr(SHF1_HR_OUT),
      SHF2_HR_IN: formatTimeToStr(SHF2_HR_IN),
      SHF2_HR_OUT: formatTimeToStr(SHF2_HR_OUT)
    });

    Object.keys(totals).forEach(key => {
      totals[key] += props?.[key] || 0;
    });

    start.add(1, 'day');
  }

  res.push({
    ixDate: '',
    empBioID: '',
    BIO1: '',
    BIO2: '',
    BIO3: '',
    BIO4: '',
    BIO5: '',
    BIO6: '',
    BIO7: '',
    BIO8: '',
    BIO9: '',
    BIO10: '',
    BIO11: '',
    BIO12: '',
    SYS_AM_IN: '',
    SYS_AM_OUT: '',
    SYS_PM_IN: '',
    SYS_PM_OUT: '',
    SYS_OT_IN: '',
    SYS_OT_OUT: '',
    HR_AM_IN: '',
    HR_AM_OUT: '',
    HR_PM_IN: '',
    HR_PM_OUT: '',
    HR_OT_IN: '',
    HR_OT_OUT: '',
    ...totals
  });

  return res;
}

export default generateTimeLogsData;
